<template>
    <div class="wizard-panel">
        <panel>
            <panel-top-bar variant="primary-dark" with-logo />
            <div class="panel-content">
                <h1 class="text-primary-dark">Setup Profile</h1>
                <h6 class="text-primary-dark">Create an account</h6>
                <form>
                    <div class="d-flex align-items-center mt-4 mb-3">
                        <avatar size="xl" class="flex-shrink-1" />
                        <a href="#" class="lead flex-grow-1 ml-4" @click.prevent="toggleUploadPanel">Add Profile Image</a>
                    </div>
                    <div class="form-group">
                        <input type="text" name="company_name" class="form-control" placeholder="Company Name" />
                    </div>
                    <div class="form-group">
                        <div class="input-group">
                            <div class="input-group-prepend">
                                <select class="custom-select">
                                    <option>+1</option>
                                </select>
                            </div>
                            <input type="text" class="form-control" placeholder="Phone Number" />
                        </div>
                    </div>
                    <div class="row">
                        <div class="col">
                            <div class="form-group">
                                <input type="text" class="form-control" placeholder="First Name" />
                            </div>
                        </div>
                        <div class="col">
                            <div class="form-group">
                                <input type="text" class="form-control" placeholder="Last Name" />
                            </div>
                        </div>
                    </div>
                    <div class="form-group">
                        <input type="text" class="form-control" placeholder="Address" />
                    </div>
                    <div class="row">
                        <div class="col">
                            <div class="form-group">
                                <input type="text" class="form-control" placeholder="City" />
                            </div>
                        </div>
                        <div class="col">
                            <div class="form-group">
                                <input type="text" class="form-control" placeholder="Zip" />
                            </div>
                        </div>
                    </div>
                    <div class="form-group mt-3">
                        <button type="submit" class="btn btn-primary btn-rounded btn-block">Create Account</button>
                    </div>
                </form>
            </div>
            <div class="panel-footer panel-footer-box">
                <button type="button" class="btn btn-block btn-default btn-lg text-primary-dark" @click="onBack">Back</button>
            </div>
        </panel>
        <panel v-if="uploadPhoto">
            <panel-top-bar variant="primary" closable @close="toggleUploadPanel" />
            <div class="panel-content">
                <h1 class="text-primary-dark">Profile Image</h1>
                <h6 class="text-primary-dark">Create an account</h6>
                <div class="uploader-drop-area mt-5">
                    <img src="/assets/images/icon-uploader.svg" alt="Upload" class="uploader-icon" />
                    <div class="text-primary-dark">Drag or select a file to upload to use as a profile image</div>
                </div>
                <p class="lead mb-0 text-primary-dark font-weight-semibold mb-4">Or select an avatar to represent yourself till you find a photo you like</p>
                <div class="row mb-4">
                    <div class="col-auto">
                        <avatar src="/assets/images/avatar-dummy-1.svg" />
                    </div>
                    <div class="col-auto">
                        <avatar src="/assets/images/avatar-dummy-2.svg" />
                    </div>
                    <div class="col-auto">
                        <avatar src="/assets/images/avatar-dummy-3.svg" />
                    </div>
                    <div class="col-auto">
                        <avatar src="/assets/images/avatar-dummy-4.svg" />
                    </div>
                </div>
                <button type="submit" class="btn btn-primary btn-rounded btn-block">Confirm</button>
            </div>
        </panel>
    </div>
</template>

<script>
    import Panel from "../../components/Panel";
    import PanelTopBar from "../../components/PanelTopBar";
    import Avatar from "../../components/Avatar/Avatar";

    export default {
        name: 'SetupProfile',
        components: {
            Avatar,
            PanelTopBar,
            Panel
        },
        data() {
            return {
                uploadPhoto: false
            }
        },
        methods: {
            toggleUploadPanel() {
                this.uploadPhoto = !this.uploadPhoto;
            },
            onBack() {
                this.$router.push({ name: 'dashboard' });
            }
        }
    }
</script>
