<template>
    <div :class="['avatar border rounded-sm', {[`avatar-${size}`]: true}]">
        <img :src="imgSrc" :alt="alt" />
    </div>
</template>

<script>
    import avatarPlaceholder from '../../assets/images/avatar-placeholder.svg';

    export default {
        name: 'Avatar',
        props: {
            src: String,
            alt: String,
            size: {
                type: String,
                default: 'md'
            }
        },
        computed: {
            imgSrc() {
                return this.src || avatarPlaceholder;
            }
        }
    }
</script>
